import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Unul dintre cele mai solicitate cursuri de mai bine de 20 de ani în România!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Orice slujitor și-ar dori să cunoască bine Epistola către Romani; să poată interpreta și folosi materialul de apărare a Evangheliei din epistola către Galateni; să cunoască și să predice o învățătură sănătoasă despre mântuirea prin credință și despre rolul și locul faptelor creștinului.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Un alt mare avantaj al acestui curs este că oferă în cele 15 lecții prezentarea, aplicarea și apărarea sistematică a mesajului evanghelic. Cursul este, prin modul în care este structurat, și un bun profesor de mare ajutor pentru student, dar în același timp lasă loc de multă interacțiune la grup. Sunt doar câteva din aspectele care fac să merite timpul necesar acordat acestui curs.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Problemele teologice trebuie examinate întotdeauna în lumina Scriptutii. Cu acest gînd vom studia aceste epistole. O vom face cu reverență pentru că sunt o parte a Cuvîntului Său; într-o atitudine de rugăciune pentru că avem nevoie de călăuzirea Duhului Sfînt; cu multă atenție, în mod analitic, pentru că îl iubim pe Domnul cu inima și cu mintea; cu recunoștință pentru că am experimentat harul Lui și acum putem să-l înțelegem …”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    